export default {
  name: "header-section",
  components: {},
  props: ["headerSectionTitle"],
  data() {
    return {};
  },
  computed: {},
  mounted() {},
  methods: {}
};
