export default {
  name: "dialog-dashboard",
  components: {},
  props: ["simpleDialogData", "title"],
  data() {
    return {
      dialog: false
    };
  },
  computed: {},
  mounted() {},
  methods: {}
};
